.partner--item {
  a {
    border-bottom: 0 !important;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;

      img {
        opacity: .6;
      }
    }

    img {
      transition: all .3s ease;
    }
  }
}
