.accordion--wrapper {
  margin: 3rem 0;
  text-align: left;

  label {
    position: relative;
    display: block;
    margin: 0;
    padding: 1rem;
    cursor: pointer;
    background-color: $color-01;
    border-left: .5rem solid $color-02;
    transition: all .3s ease;

    &:hover {
      background-color: rgba($color-01, .6);
    }

    &:after {
      content: '';
      position: absolute;
      width: 3rem;
      height: 2rem;
      right: .75rem;
      top: 50%;
      transform: translateY(-50%);
      background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjgzIDE2LjQybDkuMTcgOS4xNyA5LjE3LTkuMTcgMi44MyAyLjgzLTEyIDEyLTEyLTEyeiIvPjxwYXRoIGQ9Ik0wLS43NWg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+) no-repeat center center;
    }

    span {
      display: inline-block;
      padding-right: 3.75rem;
    }
  }

  input {
    display: none;

    &:checked + label:after {
      transform: translateY(-50%) rotate(180deg);
    }

    &:checked ~ article {
      max-height: inherit;
      padding: 1.5rem;
      border-color: rgba($black, .2);
    }
  }


  article {
    overflow: hidden;
    max-height: 0;
    position: relative;
    border: .1rem solid transparent;
    border-top: 0;
    transition: max-height .3s ease-in-out;

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.accordion--item {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

