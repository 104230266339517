.footer {
  background-color: $black;
}

.footer--newsletter {
  padding: 1.5rem 0;
  background-color: $color-01;

  .h2-like {
    font-weight: 600;
  }
}

.footer--newsletter__content {
  @include respond-to("medium") {
    margin-bottom: 2rem;
  }

  @include respond-to("medium-up") {
    padding: 2rem 0;
  }

  p {
    &:first-of-type {
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }
}

.footer--newsletter__sub {
  max-width: 50rem;

  @include respond-to("small") {
    flex-direction: column;
  }

  @include respond-to("medium-up") {
    padding: 2rem;
  }

  [type="submit"] {
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.newsletter--email {
  flex: 1;
}

.newsletter--rgpd {
  font-size: 1.4rem;
  line-height: 1.3;
}

[class*="newsletter-alert"] {
  color: $white;
  padding: .5rem;
  font-size: 1.6rem;
  width: 100%;
}

.newsletter-alert__success {
  background-color: $color-success;
}

.newsletter-alert__error {
  background-color: $color-danger;
}

.footer--top {
  padding: 5rem 0 0;
  color: rgba($white, .8);

  @include respond-to("small") {
    margin-bottom: 3rem;
  }
}

.footer--top__title {
  color: $white;
  text-transform: uppercase;
}

.footer--logo {
  text-decoration: none;

  img {
    max-width: 15rem;
    margin-bottom: 1rem;
  }

  span {
    width: 100%;
    display: inline-block;
    color: $text;
    font: 600 2.2rem $font-stack-title;
    text-transform: uppercase;
    letter-spacing: .05rem;
  }
}

.footer--description {
  font-size: 1.4rem;
  line-height: 1.2;
}

.footer--top__nav {
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  li {
    margin-bottom: 0;
  }

  .link {
    font-size: 1.6rem;
    color: rgba($white, .8);
    text-decoration: none;
    border-bottom: .2rem solid $color-01;
    transition: all .3s ease;

    &:hover,
    &:active,
    &:focus {
      color: $white;
      background-color: transparent;
    }
  }
}

.footer--top__informations {
  a {
    text-decoration: none;

    i {
      color: $color-01;
    }

    span {
      border-bottom: .2rem solid $color-01;
      color: rgba($white, .8);
      transition: color .3s ease;

      &:hover,
      &:active,
      &:focus {
        color: $white;
      }
    }
  }
}

.footer--top__social {
  position: relative;

  .link {
    color: $color-01;
    font-size: 2.5rem;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}

.footer--top__support {
  img {
    max-width: 15rem;
    margin-bottom: 2rem;
    padding: .5rem;
  }
}

.footer--bottom {
  border-top: .1rem solid rgba($white, .2);
}

.footer--bottom__sub {
  display: flex;
  font: 1.4rem $font-stack-title;
  color: $white;

  @include respond-to("medium") {
    flex-direction: column;
    align-items: center;
  }

  @include respond-to("medium-up") {
    justify-content: space-between;
  }

  a, .link {
    color: $color-01;

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}

.footer--bottom__sub-sub {
  @include respond-to("medium") {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.footer--to-top {
  position: fixed;
  z-index: 9999;
  right: 1.5rem;
  bottom: 1.5rem;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  text-align: center;
  font-size: 2rem;
  color: $color-01;
  border: .1rem solid rgba($white, .2);
  background-color: $black;
  box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);
  transform: translateY(6rem);
  transition: transform .3s ease, box-shadow .3s ease;

  &.is-scrolled {
    transform: translateY(0);
  }

  &:focus {
    outline: none;
  }
}
