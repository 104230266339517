.modular--missions {
  padding: 5rem 0;
  background-color: $background-grey;
}

.missions--item {
}

.missions--item__image {
  img {
    border-radius: 50%;
  }
}

.missions--item__description {
  font-size: 1.6rem;
}

