.modular--presentation {
  padding: 2.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond-to("large-up") {
    padding: 5rem 0;
  }

  &.is--highlighted {
    background-color: $color-01;
  }

  .container-tiny {
    margin: 0;
  }

  h1 {
    span {
      display: inline-block;
      width: 100%;
      margin-top: 1rem;
      color: $text;
      text-transform: inherit;
      font: 300 3.2rem $font-stack-title;
    }
  }
}
