.blog--taxonomy__sub {
  padding: 0;

  li {
    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:before {
      content: none;
    }

    .link {
      border: 0;
    }

    .btn--black {
      color: $white;
    }
  }
}
