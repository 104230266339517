.breadcrumbs {
  margin: 2rem 0 3rem;
  padding: 0;
  list-style: none;

  li {
    margin-bottom: 0 !important;

    &:first-child {
      > span,
      > a {
        margin-left: 0;
      }
    }
    > span,
    > a {
      margin: 0 .5rem;
      font-size: 1.6rem;

      &.link {
        color: $text;
        text-decoration: none;
        border-bottom: .2rem solid $text;
        transition: all .2s ease;

        &:hover {
          background-color: $color-01;
        }
      }
    }
  }
}
