.event {
  position: relative;
  background-color: $white;
  transition: all .3s ease;
  box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);

  &:hover {
    box-shadow: 0 .2rem 1.2rem 0 rgba($black, .3);
  }

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  &:not(.is--expanded) {
    &:hover,
    &:active,
    &:hover {
      .event--image {
        img {
          transform: scale(1.1) rotate(.1deg);
        }
      }

      .btn--ghost-black {
        color: $white !important;
        background-color: $black !important;
      }
    }
  }

  &.is--expanded {
    .event--content {
      @include respond-to("medium-up") {
        width: calc(100% / 4 * 3);
      }
    }

    .event--subcontent {
      @include respond-to("medium-up") {
        width: calc(100% / 4);
      }
    }
  }

  // &.is--vertical {
  //   flex-direction: column;

  //   .event--subcontent {
  //     width: 100%;
  //   }

  //   .event--content {
  //     width: 100%;
  //   }
  // }

  // &.is--compact {

  //   &:not(:last-child) {
  //     margin-bottom: 2rem;
  //   }

  //   .event--title {
  //     font-size: 2.8rem;
  //   }

  //   .event--description {
  //     margin-top: 1.5rem;
  //   }

  //   .event-date {
  //     padding: 2rem;
  //   }

  //   .event--date__day {
  //     font-size: 4rem;
  //   }

  //   .event--image {
  //     @include respond-to("large") {
  //       display: none;
  //     }
  //   }
  // }
}

.event--content {
  order: 3;
  width: 100%;
  padding: 3rem;

  @include respond-to("medium-up") {
    order: 2;
    width: calc(100% / 5 * 3);
  }
}

.event--subcontent {
  order: 1;
  width: 100%;
  background-color: $color-01;

  @include respond-to("large-up") {
    width: calc(100% / 5);
  }
}

.event--date {
  // padding: 4rem 3rem;
  padding: 2rem;
  background-color: $color-01;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.event--date__month {
  letter-spacing: .5rem;
  text-transform: uppercase;

  .event--date__month--sub {
    position: relative;
    margin-bottom: .75rem;

    &:after {
      content: '';
      width: 100%;
      height: .1rem;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: $black;
    }
  }
}

.event--date__day {
  display: flex;
  justify-content: center;
  font-size: 7rem;
  font-weight: 600;
  line-height: 1;

  &.is--interval {
    font-size: 5rem;
  }

  span {
    display: flex;
    align-items: center;
    margin: 0 .5rem;
    font-size: 4rem;
  }
}

.event--date__hours {
  span {
    padding: .75rem;
    font-size: 1.4rem;
    color: $white;
    background-color: $black;
  }
}

.event--informations {
  flex-grow: 1;
  padding: 2rem;
}

.event--organizer {
  font-size: 1.6rem;
}

.event--location {
  font-size: 1.6rem;

  p {
    margin-bottom: .25rem;
  }
}

.event--phone_number,
.event--email {
  a {
    font-size: 1.6rem;
    text-decoration: none !important;
    border: none !important;

    span {
      border-bottom: .2rem solid $black;
      transition: color .3s ease;
    }
  }
}

.event--image {
  order: 2;
  width: 100%;
  height: 20rem;
  position: relative;
  overflow: hidden;

  @include respond-to("medium-up") {
    order: 3;
    width: calc(100% / 5 * 2);
    height: inherit;
  }

  @include respond-to("large-up") {
    width: calc(100% / 5);
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    object-fit: cover;
    width: 100%!important;
    height: 100%;
    transition: transform .2s ease-in-out;
  }
}

.event--title {
  font-weight: 600;
  font-size: 3.2rem;
  margin-bottom: 0;
}

.event--description {
  margin-top: 3rem;
  color: rgba($black, .8);
}

.event--description__sub {
  padding: 2rem 0;
}

.event--link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border: none !important;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
  }
}
