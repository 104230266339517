// Polices de caractères

$font-stack-title: "Acumin Pro", Arial, sans-serif;
$font-stack-text: "Myriad Pro", sans-serif;

// Couleurs

$text: #281f21 !default;
$grey: #999999 !default;
$background-grey: #f1f1f1 !default;

$color-01: #bb9e6b !default; // jaune
$color-02: #8e9d0c !default; // vert

$twitter: #1da1f2 !default;
$facebook: #3b5999 !default;
$linkedin: #0177b5 !default;
$pinterest: #bd1e23 !default;
$instagram: #675144 !default;
$youtube: #ff0000 !default;
