.header {
  position: fixed;
  width: 100%;
  z-index: 100;
  background-color: $white;

  &.is-scrolled {
    min-height: 4.5rem;
    box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);

    .header--top {
      display: none;
    }

    .navigation--logo {
      width: 5rem;
    }
  }

  &.is-open-mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;

    .header--navigation {
      flex-grow: 1;
    }

    .navigation--menu {
      flex-direction: column;
    }

    .navigation--list {
      display: block;
    }

    .navigation--sub {
      position: relative;
      height: calc(100vh - 11rem);
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }

    .navigation--left {
      @include respond-to("large") {
        margin-bottom: 1.5rem;
      }
    }

    .header--top {
      @include respond-to("medium") {
        display: block;
        order: 1;
        padding-top: 1rem;
        position: relative;
        z-index: 1;

        .header--top__sub {
          justify-content: center;
        }

        .header--top__infos {
          display: none;
        }

        .header--top__socials {
          & > *:not(.header--top__social) {
            display: none;
          }
        }

        .header--top__social {
          font-size: 2.4rem !important;
          margin: 0 .5rem !important;
        }
      }
    }
  }
}

.header--top {
  padding: .5rem 0;

  @include respond-to("medium") {
    display: none;
  }

  @include respond-to("medium-up") {
    border-bottom: .1rem solid rgba($black, .075);
    background-color: $color-01;
  }

  [class*="container"] {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.header--top__sub {
  display: flex;
  justify-content: space-between;

  a, .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-right: 1rem;
    font-size: 1.4rem;

    span {
      color: $text;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        color: rgba($text, .8);
      }
    }

    i {
      margin-right: .5rem;
    }
  }
}

.header--top__social {
  display: flex;
  align-items: center;
  margin-right: 0 !important;
  font-size: 1.8rem !important;
  color: $text;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: rgba($text, .8);
  }
}

.header--navigation {
  [class*="container"] {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navigation--left {
  @include respond-to("large") {
    width: 100%;
  }
}

.navigation--logo {
  display: inline-block;
  position: relative;
  width: 5rem;
  text-decoration: none;

  @include respond-to("large-up") {
    width: 10rem;
  }

  img {
    width: 100%;
  }
}

.navigation--sub {
  @include respond-to("large-up") {
    align-items: center;
    margin-left: auto;
  }
}

.navigation--list {
  width: 100%;
  list-style: none;
  margin-bottom: 0;
  padding: 0;

  @include respond-to("large") {
    &.is-top {
      display: none;
    }
  }

  @include respond-to("large-up") {
    max-height: 5rem;
  }

  li {
    position: relative;
    display: flex;
    margin-bottom: 0;

    @include respond-to("large") {
      flex-direction: column;
      width: 100%;
    }

    @include respond-to("large-up") {
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        .navigation--list__sub {
          visibility: visible;
          opacity: 1;
          margin: 0;
        }
      }
    }

    &:before {
      content: none;
    }

    .link {
      &.is--clicked {
        & + .navigation--list__sub {
          visibility: visible;
          opacity: 1;
          margin: 0 0 0 1.5rem;
          display: block;
        }
      }
    }
  }

  .link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    font: 600 2rem $font-stack-title;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .1rem;
    color: $text;
    border: none;

    @include respond-to("large-up") {
      font-size: 1.6rem;
    }

    &:hover,
    &:focus,
    &:active {
      border: none;
      background-color: $color-01;
    }

    &.not--routable {
      cursor: default;
    }

    &.is--active {
      span {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: .2rem;
          background-color: $color-01;
        }
      }
    }

    i {
      margin-left: .25rem;
      font-size: 1.4rem;
    }
  }
}

.navigation--list__sub {
  width: 22rem;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  display: none;

  @include respond-to("large-up") {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    background-color: $white;
    box-shadow: 0 1rem 2rem 0 rgba($black, .1);
    z-index: 999;
  }

  li {
    &:hover,
    &:focus,
    &:active {
      a, .link {
        color: $black;
        background-color: $color-01;

        span {
          &:after {
            content: none;
          }
        }
      }
    }
  }

  .link {
    padding: 1rem;
    width: 100%;
    font: 1.5rem $font-stack-text;
    color: rgba($black, .65);
    text-transform: inherit;
    text-align: left;
    letter-spacing: inherit;

    &.is--active {
      color: $black;

      @include respond-to("large") {
        span {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: .2rem;
            background-color: $color-01;
          }
        }
      }

      @include respond-to("large-up") {
        background-color: $color-01;
      }
    }

    span {
      border: none;
    }
  }

  i {
    display: none;
  }

  .navigation--list__sub {
    display: none;
  }
}

.hamburger {
  display: flex;
  align-items: center;
  margin-left: auto;

  &:focus {
    outline: none;
  }

  @include respond-to("large-up") {
    display: none;
  }
}

.hamburger-label {
  margin-right: 1.25rem;
  font: 1.6rem $font-stack-title;
  color: $text;
  letter-spacing: .1rem;
  text-transform: uppercase;
  transition: .3s color ease;
}
