html {
  display: flex;
  flex-direction: column;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: $font-stack-text;
  color: $text;
}

main {
  flex: 1 1 auto;
}

h1, .h1-like {
  font: 600 4rem $font-stack-title;
  text-transform: uppercase;
  color: $text;
  margin-bottom: 1.5rem;
  line-height: 1.2;

  @include respond-to("small-up") {
    font-size: 4.6rem;
  }

  span {
    color: $color-01;
  }
}

h2, .h2-like {
  font: 300 3.2rem $font-stack-title;
  text-transform: uppercase;
  color: $text;
  margin-bottom: 1rem;
  line-height: 1.2;

  @include respond-to("medium-up") {
    font-size: 3.8rem;
  }
}

h3, .h3-like {
  font: 300 2.4rem $font-stack-title;
  text-transform: uppercase;
  color: $text;
  margin-bottom: 1rem;
  line-height: 1.4;

  @include respond-to("medium-up") {
    font-size: 2.6rem;
  }
}

h4, .h4-like {
  font: 300 2rem $font-stack-title;
  color: $text;
  margin-bottom: 1rem;
  line-height: 1.4;

  @include respond-to("medium-up") {
    font-size: 2.2rem;
  }
}

h5, .h5-like {
  font: 300 1.6rem $font-stack-title;
  color: $text;
  margin-bottom: 1rem;
  line-height: 1.4;

  @include respond-to("medium-up") {
    font-size: 1.8rem;
  }
}

h6, .h6-like {
  font: 300 1.2rem $font-stack-title;
  color: $text;
  margin-bottom: 1rem;
  line-height: 1.4;

  @include respond-to("medium-up") {
    font-size: 1.4rem;
  }
}

b, strong {
  font-weight: 600;
}

ul {
  margin-bottom: 3rem;
  list-style: none;

  li {
    position: relative;
    margin-bottom: .75rem;

    &:before {
      content: "";
      position: absolute;
      left: -2rem;
      top: 1rem;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      background-color: $color-01;
    }

    a, .link {
      border-bottom: .2rem solid $text;

      &:hover,
      &:active,
      &:focus {
        color: $text;
        text-decoration: none;
        background-color: $color-01;
      }
    }
  }

  ul {
    margin: 1.5rem 0;

    li {
      &:before {
        background-color: $text;
      }
    }

    a, span {
      border-bottom: .2rem solid $text;
    }

    ul {
      margin: 1.5rem 0;

      li {
        &:before {
          border: .2rem solid $text;
          background-color: transparent;
        }
      }

      a, span {
        border-bottom: .1rem solid $text;
      }
    }
  }
}

figcaption {
  margin-top: 1.5rem;
  text-align: center;
  transition: opacity .3s ease-in-out;
}

hr {
  width: 100%;
  margin: 3rem 0;
  color: $grey;
  background-color: $grey;
}

a,
.link {
  color: $text;
  transition: all .3s ease;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: lighten($text, 20%);
  }
}

blockquote {
  color: $color-02;

  &:before {
    color: $color-02;
  }
}
