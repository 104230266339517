// Fonts (Polices de caractère)


  // Acumin Pro Condensed - Light

  @font-face {
    font-family: 'Acumin Pro';
    src: url('../fonts/acumin-pro/condensed/light/acumin-pro-condensed-light.woff2') format('woff2'),
          url('../fonts/acumin-pro/condensed/light/acumin-pro-condensed-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  // Acumin Pro Condensed - Bold

  @font-face {
    font-family: 'Acumin Pro';
    src: url('../fonts/acumin-pro/condensed/bold/acumin-pro-condensed-bold.woff2') format('woff2'),
          url('../fonts/acumin-pro/condensed/bold/acumin-pro-condensed-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  // Myriad Pro - Bold

   @font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/myriad-pro/bold/myriad-pro-bold.woff2') format('woff2'),
          url('../fonts/myriad-pro/bold/myriad-pro-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  // Myriad Pro - Regular

  @font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/myriad-pro/regular/myriad-pro-regular.woff2') format('woff2'),
          url('../fonts/myriad-pro/regular/myriad-pro-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  // Myriad Pro - Light

  @font-face {
    font-family: 'Myriad Pro';
    src: url('../fonts/myriad-pro/light/myriad-pro-light.woff2') format('woff2'),
          url('../fonts/myriad-pro/light/myriad-pro-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

