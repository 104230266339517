.modular--header {
  position: relative;
  padding: 0 0 4rem;
  min-height: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modular--header__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.65;

  img {
    object-fit: cover;
    width: 100% !important;
    height: 100%;
  }
}

.modular--header__sub {
  position: relative;
  z-index: 2;
  color: #fff;

  p {
  }

  img {
    max-width: 15rem;

    @include respond-to("small-up") {
      max-width: 25rem;
    }

    @include respond-to("medium-up") {
      max-width: 35rem;
    }

    @include respond-to("large-up") {
      max-width: 45rem;
    }
  }
}
