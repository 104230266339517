.blog--item {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);

  &:hover {
    .blog--item__img {
      img {
        transform: scale(1.1) rotate(.1deg);
      }
    }
  }

  @include respond-to("tiny") {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

.blog--item__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
  background-color: $white;

  h2 {
    font-weight: 600;
  }

  [class*="btn"] {
    margin-top: auto;
    align-self: flex-start;
  }
}

.blog--item__date {
  font: 400 2.2rem $font-stack-title;
  color: rgba($text, .6);
}

.blog--item__img {
  position: relative;
  overflow: hidden;

  img {
    position: relative;
    width: 100%;
    height: 0;
    display: block;
    padding-bottom: 72.28%;
    transition: transform .2s ease-in-out;

    &.b-loaded {
      height: auto;
      padding-bottom: 0;
      z-index: 1;
    }
  }
}

.blog--item__link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border: none !important;
}
