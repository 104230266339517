.page {
  margin-top: 8rem;
  padding: 0 0 5rem 0;

  @include respond-to("large-up") {
    margin-top: 12.5rem;
  }

  blockquote {
    margin: 2rem 0;
    padding: 0 1.5rem 0 4rem;
    font-size: 2.2rem;

    @include respond-to("large-up") {
      margin: 4rem 0;
      padding: 0 3rem 0 8rem;

      &:before {
        font-size: 5em;
        left: 1.5rem;
      }
    }

    &:before {
      top: 1.5rem;
      font-size: 3em;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.page--header {
  position: relative;
  margin-top: 4.5rem;

  @include respond-to("medium-up") {
    margin-top: 4.5rem;
  }

  @include respond-to("large-up") {
    margin-top: 4.5rem;
  }

  &:before {
    display: block;
    width: 100%;
    padding-top: 25%;
    content: "";

    @include respond-to("large-up") {
      padding-top: 15%;
    }
  }

  picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100% !important;
      height: 100%;
    }
  }

  & + .page {
    position: relative;
    margin-top: -2rem;
  }
}

.page--header__sub {
  position: relative;
  z-index: 2;
  padding: 2.5rem 0;

  @include respond-to("large-up") {
    padding: 10rem 0;
  }
}

.page--content {
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: initial;
  }

  ul {
    list-style: none;

    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  ol {
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  a:not([class*="btn"]):not(.blog--item__link):not(.page--children__link):not([class*="button--share"]) {
    text-decoration: none;
    border-bottom: 0.2rem solid $text;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-01;
      color: $text;
    }
  }
}

.page--left {
  @include respond-to("large-up") {
    padding-right: 4rem;
  }
}

.is--important {
  position: relative;
  margin: 2rem 0 4rem;
  padding: 2rem 2rem 4rem;
  font: 400 2.4rem $font-stack-title;
  color: $text;
  line-height: 1.4;
  border-left: 1rem solid $color-02;
  background-color: $color-01;

  @include respond-to("large-up") {
    padding: 4rem 4rem 8rem;
    font-size: 2.8rem;
  }

  &:after {
    content: "";
    position: absolute;
    left: -5%;
    bottom: 0;
    width: 110%;
    height: 100%;
    background: {
      image: url(../img/form.svg);
      repeat: no-repeat;
      size: contain;
      position: center 101%;
    }
  }

  ul li:before {
    background-color: $text;
  }
}

.page--right {
  padding-top: 2rem;

  @include respond-to("large-up") {
    padding: 0 1.5rem;
  }
}

.page--children {
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  box-shadow: 0 0.2rem 1.2rem 0 rgba($black, 0.1);
  transition: all 0.3s ease;

  &:hover,
  &:focus,
  &:active {
    background-color: $color-01;

    a {
      background-color: transparent !important;
    }
  }

  p {
    span {
      border-bottom: 0.2rem solid $black;
    }
  }

  a {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: none !important;
  }
}

.sidebar--navigation {
  padding: 2rem;
  box-shadow: 0 0.2rem 1.2rem 0 rgba($black, 0.1);

  .is--active {
    font-weight: 600;
    text-decoration: none;
    border-color: $color-01;
  }

  p {
    margin-top: 0;
  }
}

.sidebar--navigation__1 {
  position: relative;
  left: -1.5rem;
}
