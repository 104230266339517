.gallery--item {
  position: relative;

  &:hover {
    &:after {
      background-color: transparent;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba($black, .25);
    transition: .3s background-color ease;
  }
}

.gallery--item__image {
  img {
    position: relative;
    width: 100%;
    height: 0;
    display: block;
    padding-bottom: 100%;

    &.b-loaded {
      height: auto;
      padding-bottom: 0;
      z-index: 1;
    }
  }
}

.gallery--item__title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 1rem;
  text-align: center;
  font-weight: 600;
  color: $white;
  font-size: 3.2rem;
}
