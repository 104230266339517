.page--maintenance {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: {
    size: cover;
    repeat: no-repeat;
    position: 50% 50%;
    image: url("img/background-maintenance.jpg");
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: .6;
  }

  &_sub {
    max-width: 50rem;
    position: relative;
    z-index: 2;
    padding: 2rem;
    background-color: $white;
  }

  img {
    max-width: 15rem;
  }

  [class*="btn"] {
    width: 100%;
  }
}
