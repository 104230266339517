.modular--block {
  padding: 2.5rem 0;

  @include respond-to("large-up") {
    padding: 5rem 0 0;
  }

  &.is--lastchild {
    padding-bottom: 2.5rem;

    @include respond-to("large-up") {
      padding-bottom: 5rem;
    }
  }
}

.modular--block__sub {
  box-shadow: 0 .2rem 1.2rem 0 rgba($black, .1);
}

.modular--block__left {
  position: relative;

  @include respond-to("medium") {
    order: -1;
  }
}

.modular--block__image {
  @include respond-to("medium-up") {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      position: relative;
      object-fit: cover;
      width: 100%!important;
      display: block;
      padding-bottom: 100%;

      &.b-loaded {
        height: 100%;
        padding-bottom: 0;
        z-index: 1;
      }
    }
  }
}

.modular--block__right-sub {
  padding: 4rem;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.6rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
