.article--single {
  width: 100%;

  &.is-page {
    h1 {
      margin-top: 0;
    }

    .article--content {
      max-width: 100%;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: initial;
  }
}

.article--header {
  max-width: 80rem;
}

.article--date {
  margin-bottom: 4rem;
  padding: 0;
  font: 400 2.2rem $font-stack-title;
  color: rgba($text, .6);
}

.article--footer {
  width: 100%;
  padding: 5rem 0 0;
}

.article--share {
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  [class^=button] {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 25rem;
    padding: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    border: .2rem solid transparent;
    transition: background-color .3s ease-in-out;

    @include respond-to("small") {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 1.75rem;
      }
    }

    &:hover {
      span {
        color: $white;
      }
    }

    i {
      margin-right: .75rem;
      font-size: 1.6rem;
    }

    span {
      display: flex;
      align-content: center;
      font-size: 1.4rem;
      transition: color .3s ease-out;
    }

    svg {
      margin-right: 1rem;
      transition: fill .3s ease-out;
    }
  }

  .button--share__{
    &facebook {
      border-color: $facebook;
      color: $facebook;

      svg {
        fill: $facebook;
      }

      &:hover {
        background-color: $facebook;

        svg {
          fill: $white;
        }
      }
    }

    &twitter {
      border-color: $twitter;
      color: $twitter;

      svg {
        fill: $twitter;
      }

      &:hover {
        background-color: $twitter;

        svg {
          fill: $white;
        }
      }
    }

    &linkedin {
      border-color: $linkedin;
      color: $linkedin;

      svg {
        fill: $linkedin;
      }

      &:hover {
        background-color: $linkedin;

        svg {
          fill: $white;
        }
      }
    }

    &pinterest {
      border-color: $pinterest;
      color: $pinterest;

      svg {
        fill: $pinterest;
      }

      &:hover {
        background-color: $pinterest;

        svg {
          fill: $white;
        }
      }
    }
  }
}

.article--prev-next {
  width: 100%;
  position: relative;

  @include respond-to("medium-up") {
    &:before {
      content: "";
      position: absolute;
      top: 25%;
      bottom: 25%;
      left: 50%;
      transform: translateX(-50%);
      width: .2rem;
      background-color: $color-01;
    }
  }
}

.article--nav {
  position: relative;
  padding:0;
 .article--nav__title {
  width:100%;
  }
  &:hover,
  &:focus,
  &:active {
    .article--nav__title {
      color: $color-01;

    }
  }

  @include respond-to("medium") {
    margin-bottom: 4rem;
  }

  &.is-prev {
    @include respond-to("medium-up") {
      padding-right: 4rem;
    }

    .article--nav__label {
      justify-content: flex-start;
    }

    .fa-layers {
      margin-right: .75rem;
    }
  }

  &.is-next {
    margin-left: auto;
    text-align: right;
    .article--nav__title{
    }

    @include respond-to("medium-up") {
      padding-left: 4rem;
    }

    .article--nav__label {
      justify-content: flex-end;
    }

    .fa-layers {
      margin-left: .75rem;
    }
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

  }
}

.article--nav__label {
  margin-bottom: 1rem;

  // .fa-layers {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 2.5rem;
  //   height: 2.5rem;
  //   background-color: $color-01;
  // }

  // i {
  //   font-size: 1.2rem;
  //   color: $white;
  // }

  span:not(.fa-layers) {
    text-transform: uppercase;
    font-family: $font-stack-title;
    color: $color-01;
    font-size: 1.8rem;
    letter-spacing: .1rem;
  }
}

.article--nav__title {
  font: 300 2.2rem $font-stack-text;
  color: $text;
  line-height: 1.4;
  transition: .3s color ease-in-out;
}

.articles--related {
  padding: 5rem 0;
  background-color: rgba($color-02, .075);
}
